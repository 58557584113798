<template src="./account.html"></template>
<style scoped src="./account.css"></style>

<script>
import { mapGetters, mapActions } from 'vuex'
import accountConstants from '@/constants/AccountConstants.vue'
import Loading from 'vue-loading-overlay'
import AccountService from '@/services/AccountService'
import { required, email, alpha} from 'vuelidate/lib/validators'
import AppConstants from '@/constants/AppConstants.vue'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import Utils from '@/utils/utils.vue'
import modal from '@/components/modal/notification/modal'

export default {
    data() {
        return {            
            useremail: '',
            user: null,
            showAccountDetails: false,
            provincesList: accountConstants.provincesList,
            accountStatus: accountConstants.accountStatus,
            accountSubStatus: accountConstants.accountSubStatus,
            riskScorceList: accountConstants.riskScorceList,
            emailStatusList: accountConstants.emailStatusListForSupport,
            mobileStatusList: accountConstants.mobileStatuslistForSupport,
            mrmsSupportStatusList:accountConstants.mrmsSupportStatusList,
            eidStatusList_forSupport : accountConstants.eidStatusList_forSupport,
            userIdType : accountConstants.userIdType,
            goldMemberShipList: accountConstants.goldMemberShipList,
            wfeFlagList: accountConstants.wfeFlagList,
            loaderVisible: false,
            currentPage: 1,
            perPage : 8,
            notes: '',
            errorMessage : AppConstants.formsMessages,
            isMobileStatusNonEditable : true,
            isMrmsStatusNonEditable : true,
            isEidStatusNonEditable : true,
            disableMrmsSkippedcheckbox : false,
            disableEidSkippedcheckbox: false,
            defaultSelectedValueeid : '',
            defaultSelectedValueMrms : '',
            defaultSelectedValuePhone : '',
            countryList: accountConstants.countryList,
            Utils : Utils,
            timeZoneList: [],
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            forceUpdate: false,
            highlightingErrorFields: {},
            fields: [
                {key: 'CreateDateUtc', label: 'Created Date'},
                {key: 'CreatedBy', label: 'Created by'},
                {key: 'Notes', label: 'Note' }
            ],
            accountBalance: ''
        }
    },
    directives : {
        mask
    },
    methods : {
        ...mapActions (['fetchAccountDetails']),
        async onShowAccountDetails() {   
            try {             
                this.$v.useremail.$touch()
                if (this.$v.useremail.$invalid) {                    
                    return  
                }              
                this.loaderVisible = true
                this.$v.$reset()
                await this.fetchAccountDetails(this.useremail)
                this.user = await this.getAccountDetails
                this.showAccountDetails = true           
                this.user.DateOfBirth = this.user.DateOfBirth === '' ? null : new Date(this.user.DateOfBirth)
                this.user.skipEqufax = this.user.EIDSkipped === "true" ? "Yes" : "No"
                this.user.SkipEquifaxVerification = false
                this.user.NewMRMSSkipped = false
                this.user.Country = this.user.Country === '' ? 'Canada' : this.user.Country
                await AccountService.methods.GetAccountBalance(this.useremail)
                .then(res => {
                    this.accountBalance = res.csAccountBalanceResponse.Balance._text? res.csAccountBalanceResponse.Balance._text : ''
                }).catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                this.loaderVisible = false
                if (this.user.EIDRunDate) {
                    this.user.EIDRunDate = Utils.methods.UTCtoLocal(moment(moment(this.user.EIDRunDate, 'MM/DD/YYYY hh:mm:ss A')._d).format('MM-DD-YYYY HH:mm:ss')) // UTC to Local
                }
                this.user.Notes.forEach(note => {
                    note.CreateDateUtc = Utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
                })
                setTimeout(function () {
                    let inputElements = (document.getElementById('form')).getElementsByTagName('*')
                    if(inputElements) {
                        inputElements.forEach(item => {
                            if (item.hasAttribute('disabled')) {
                                item.setAttribute("title", item.value)
                            }
                        })
                    }
                    let selectElements = document.getElementById('form').getElementsByTagName('select')
                    if(selectElements) {
                        selectElements.forEach(item => {
                            if (item.hasAttribute('disabled')) {
                                item.setAttribute("title", item.selectedOptions[0].innerText)
                            }
                        })
                    }
                },5000) // for placing title on disabled fields
            }           
            catch (error) {   
                this.loaderVisible = false
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)           
            }
        },
        async onSubmit(event){
            this.$bvModal.msgBoxConfirm('Are you sure you want to update the account ?')
            .then( async value => {
                if(value) {
                    if (this.user.AccountStatus === "0" || this.user.AccountStatus === "4") { // account status 0 - enabled, 4 -signedup
                        if (this.user.DateOfBirth) {
                            if (!this.validateDateOfBirth(this.user.DateOfBirth)) {
                                return
                            }
                        }
                        this.$v.user.$touch()
                        if (this.$v.$invalid) {
                            this.showAlert(null, 'Please fill Required details')    
                            return
                        }
                    }
                    // if (this.user.MobileNumber && this.user.MobileNumber !== '') {
                    //     this.user.MobileNumber = '+1' + this.user.MobileNumber.replace(/[^\w\s]/gi, '')
                    // }
                    // if(this.user.PhoneNumber && this.user.PhoneNumber !== '') {
                    //     this.user.PhoneNumber =  '+1' + this.user.PhoneNumber.replace(/[^\w\s]/gi, '')
                    // }
                    this.loaderVisible = true
                    await AccountService.methods.UpdateUserProfile(this.user)
                    .then( () => {
                        this.showAlert(1, 'Account Profile Successfully Updated')  
                        this.loaderVisible = false
                    })
                    .catch(err => {
                        if (!this.forceUpdate && (err.errorCode === 37 || err.errorCode === 38 || err.errorCode === 51 || err.errorCode === 52)) { 
                            this.$bvModal.msgBoxConfirm('Warning: if you update user details, user account may be placed under review for 24 hours. user will not be able to perform transactions while under review.')
                            .then(async res => {
                                if (res) {
                                    this.forceUpdate = true
                                    AccountService.methods.UpdateUserProfile(this.user)
                                    .then(resp => {
                                        this.showAlert(1, 'Account Profile Successfully Updated')  
                                        this.loaderVisible = false
                                    })
                                    .catch(er => {
                                        this.showDataverificationErrors(er)
                                        this.alertObject = er
                                        this.$bvModal.show(this.$refs.childModal.id)
                                        this.loaderVisible = false
                                    })
                                }
                            })
                        } else {
                            this.showDataverificationErrors(err)
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                            this.loaderVisible = false
                        }
                    })            
                    event.preventDefault()
                }
            })           
        },
        showDataverificationErrors (obj) {
            if (obj.errorCode === 41 || obj.errorCode === 42) {
                this.highlightingErrorFields['DOB'] = true
            }
            if (obj.errorCode === 43) {
                this.highlightingErrorFields['IdType'] = true
            }
            let dataVerificationErrors = Array.isArray(obj.dataVerificationDetails) ? obj.dataVerificationDetails : [obj.dataVerificationErrors]
            dataVerificationErrors.forEach(errorField => {
                if (errorField) {
                    if (['FirstName','Number', 'Email', 'PostalCode', 'Province', 'MiddleInitial','City','LastName','PhoneNumber', 'AddressLine1', 'TimeZone'].includes(errorField.PropertyName._text)) {
                        this.highlightingErrorFields[errorField.PropertyName._text] = true
                    }
                }
            })

        },
        async handleAddNotesEvent(){
            if(this.notes.trim().length > 0) {
                this.loaderVisible = true
                await AccountService.methods.UpdateUserProfileNotes(this.notes,this.user.EmailAddress)
                .then( async() => {
                    this.notes = ''   
                    await this.updateNotesTable()
                    this.loaderVisible = false
                    this.showAlert(1, 'Notes Added Successfully')
                })
                .catch( err => {
                    this.loaderVisible = false
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)                               
                })
            } else {
                this.showAlert(null, 'Please Enter Notes')
            }
        },
        onMobileStatusChange (event) {
            this.user.MobileStatusSupport = event
            if(event === '2') {
                this.isMobileStatusNonEditable = false              
            } else {
                this.isMobileStatusNonEditable = true
            }
        },
        onMrmsStatusChange (event) {
            this.user.MrmsSupportStatus = event
            if (event === '4') {
                this.isMrmsStatusNonEditable = false
                this.disableMrmsSkippedcheckbox = true       
                this.user.NewMRMSSkipped = 'true'         
            } else {
                this.isMrmsStatusNonEditable = true
                this.disableMrmsSkippedcheckbox = false
                this.user.NewMRMSSkipped = 'false'
            }
        },
        onMrmsSkippedChange (event) {
            if(event) {
                this.isMrmsStatusNonEditable = false
            } else {
                this.isMrmsStatusNonEditable = true
            }
        },
        onEidStatusChante (event) {
            this.user.EidStatusList_forSupport = event

            if (event === '4') {
                this.isEidStatusNonEditable = false
                this.disableEidSkippedcheckbox = true
                this.user.SkipEquifaxVerification = 'true'
            } else {
                this.isEidStatusNonEditable = true
                this.disableEidSkippedcheckbox = false
                this.user.SkipEquifaxVerification = 'false'
            }                
        },
        onSkipEquifaxChange(event) {
            if(event) {
                this.isEidStatusNonEditable = false
            } else {
                this.isEidStatusNonEditable = true
            }
        },
        async updateNotesTable() {
            await this.fetchAccountDetails(this.useremail)
            let userDetails = await this.getAccountDetails
            userDetails.Notes.forEach(note => {
                note.CreateDateUtc = Utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
            })
            this.user.Notes = userDetails.Notes
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        getTimeZone () {
            this.user.TimeZone = (accountConstants.provincesList.find(item => item.ProviceCode === this.user.State)).TimeZone
        },
        validateDateOfBirth(date) {
            let today = new Date();
            let birthDate = new Date(date)
            let age = today.getFullYear() - birthDate.getFullYear()
            let month = today.getMonth() - birthDate.getMonth()
            if (month < 0 || (month === 0 && (today.getDate() < birthDate.getDate())) ) {
                age -= 1 
            }
            if (age < 18) {
                this.showAlert(null, 'user must atleast 18 years of age')
                this.$bvModal.show(this.$refs.childModal.id)
                return false
            } else if (age > 110) {
                this.showAlert(null, 'user must be less than 110 years of age')
                this.$bvModal.show(this.$refs.childModal.id)
                return false
            } else {
                return true
            }
        },
        goBack() {
            this.$router.push({
                name : this.$route.params.fromName || 'Dashboard',
                params : this.$route.params
            })
        },
        isLetter(e){
            if(/^[A-Za-z ]+$/.test(String.fromCharCode(e.keyCode))) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
    },
    computed: {
        ...mapGetters (['getAccountDetails']),
        rows() {
            return this.user.Notes.length
        },
        today () { return new Date()}
    },
    validations : {
        user : {
            City : { required },
            DateOfBirth : { required },     
            FirstName : { required },
            LastName : { required },
            MobileNumber : { required },
            PostalCode : { required },
            StreetAddress1 : { required } ,
            State : { required },
            EmailAddress : {required, email}
        },
        useremail : {
            required,
            email
        }              
    },
    components : {
        Loading, modal
    },
    created () {
        this.timeZoneList = Array.from(new Set(this.provincesList.map(item => item.TimeZone)))
        this.useremail = this.$route.params.email
        if (this.useremail) {
            this.onShowAccountDetails()
        }
        if(!(this.countryList.find(country => country.Text === 'Canada')))
        {
            this.countryList.push({
                Text :'Canada',
                Value : 'Canada',
                Currency : 'CAD',
                Code : 'CA',
                DialerCode : '+1'
            })
        }
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('type', 'text/javascript')
        recaptchaScript.setAttribute('src', 'https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=gd99-rb99-an95-zz57')
        document.body.appendChild(recaptchaScript)
    }
}
</script>